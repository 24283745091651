import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import AcademiesList from 'views/AcademiesList';
import AcademyDetails from 'views/AcademyDetails';
import ReduInfoDisplay from 'views/ReduInfoDisplay';
import './App.css';
import { showEnv } from './utils/helper';
import { useAppDispatch, useAppSelector } from 'hooks';
import { fetchRegionsSectorGeneralSettings } from 'store/regions_sector_general_settings';

// <editor-fold desc="AppRouter type definitions ...">
// </editor-fold>

// <editor-fold desc="AppRouter component definition ...">
const AppRouter = (): JSX.Element => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    showEnv();
    dispatch(fetchRegionsSectorGeneralSettings());
  }, []);

  return (
    <main className="bg-light text-dark min-vh-100 d-flex">
      <Router>
        <Switch>
          <Route path="/redu/info/:timer/:showResults/:cityId" exact>
            <ReduInfoDisplay />
          </Route>
          <Route path="/" exact>
            <AcademiesList />
          </Route>
          <Route path="/:nameSlug" exact>
            <AcademyDetails />
          </Route>
          <Redirect to="/" />
        </Switch>
      </Router>
    </main>
  );
};
// </editor-fold>

export default AppRouter;
