import React from 'react';

// <editor-fold desc="Component type definitions ...">

export type ComponentProps = {
  children: JSX.Element;
};
// </editor-fold>

// <editor-fold desc="Component component definition ...">
const Component = ({ children }: ComponentProps): JSX.Element => {
  return (
    <div className="shadow-sm p-3 mb-5 bg-white rounded w-100">{children}</div>
  );
};
// </editor-fold>

export default Component;
