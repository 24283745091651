import React from 'react';
import {
  default as RbButton,
  ButtonProps as RbButtonProps,
} from 'react-bootstrap/Button';

// <editor-fold desc="Button type definitions ...">
// </editor-fold>

// <editor-fold desc="Component component definition ...">
const Button = ({ children, ...rest }: RbButtonProps): JSX.Element => {
  return (
    <RbButton className="custom-button text-white" {...rest}>
      {children}
    </RbButton>
  );
};
// </editor-fold>

export default Button;
