import * as actionTypes from './actionTypes';
import { RegionsSectorGeneralSettings, RegionsSectorGeneralSettingsAction, DispatchType, RegionsSectorGeneralSettingsState } from './types';
import { getApiBaseUrl } from '../../utils/helper';

const initialState: RegionsSectorGeneralSettingsState = {
  loading: false,
  instance: {
    tiitus_support_text: "",
  },
  error: '',
};

const fetchRegionsSectorGeneralSettingsRequest = () => {
  return {
    type: actionTypes.GET_REGIONS_SECTOR_GENERAL_SETTINGS_REQUEST,
  };
};

const fetchRegionsSectorGeneralSettingsSuccess = (instance: RegionsSectorGeneralSettings) => {
  return {
    type: actionTypes.GET_REGIONS_SECTOR_GENERAL_SETTINGS_SUCCESS,
    data: instance,
  };
};

const fetchRegionsSectorGeneralSettingsFailure = (error: string) => {
  return {
    type: actionTypes.GET_REGIONS_SECTOR_GENERAL_SETTINGS_FAILURE,
    error: error,
  };
};

const reducer = (
  state = initialState,
  action: RegionsSectorGeneralSettingsAction,
): RegionsSectorGeneralSettingsState => {
  switch (action.type) {
    case actionTypes.GET_REGIONS_SECTOR_GENERAL_SETTINGS_REQUEST: {
      return { ...state, loading: true };
    }
    case actionTypes.GET_REGIONS_SECTOR_GENERAL_SETTINGS_SUCCESS: {
      return {
        loading: false,
        instance: action?.data as RegionsSectorGeneralSettings,
        error: '',
      };
    }
    case actionTypes.GET_REGIONS_SECTOR_GENERAL_SETTINGS_FAILURE: {
      return { loading: false, instance: { tiitus_support_text: ""}, error: action?.error as string };
    }
    default:
      return state;
  }
};

export const fetchRegionsSectorGeneralSettings = (params?: {
  type: string;
}): ((dispatch: DispatchType) => void) => {
  const urlParams = new URLSearchParams({ ...params }).toString();
  return function (dispatch: DispatchType) {
    dispatch(fetchRegionsSectorGeneralSettingsRequest());
    fetch(`${getApiBaseUrl()}get_regions_sector_general_settings/?${urlParams}`)
      .then((response) => {
        response
          .json()
          .then((data) => dispatch(fetchRegionsSectorGeneralSettingsSuccess(data)))
          .catch((error) => dispatch(fetchRegionsSectorGeneralSettingsFailure(error)));
      })
      .catch((error) => dispatch(fetchRegionsSectorGeneralSettingsFailure(error)));
  };
};

export default reducer;
