import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CompanyIntroImage from 'assests/CompanyIntroImage.png';
import CompanyIntroImageSmall from 'assests/CompanyIntroImageSmall.png';
import Image from 'react-bootstrap/Image';
import Button from 'components/Button';
import { getCompanyRegisterUrl } from '../../utils/helper';

// <editor-fold desc="CompanyIntro type definitions ...">

export type CompanyIntroProps = {
  title?: string;
  description?: string;
  sectorId: number;
};
// </editor-fold>

// <editor-fold desc="CompanyIntro component definition ...">
const CompanyIntro = ({
  title = '',
  description = '',
  sectorId,
}: CompanyIntroProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row>
        <Col xs={12} md={8} lg={9} className="p-4">
          <h2 className="text-primary">{title}</h2>
          <p>{description}</p>
          <Row>
            <Col xs="auto" style={{ marginTop: 15 }}>
              <Button href={getCompanyRegisterUrl(sectorId)} target="_blank">
                {t('Luo yritysprofiili')}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={CompanyIntroImageSmall}
            />
            <source media="(min-width: 796px)" srcSet={CompanyIntroImage} />
            <Image
              src={CompanyIntroImage}
              alt="Tiitus company intro image"
              className="mw-100 d-block mx-auto"
            />
          </picture>
        </Col>
      </Row>
    </Container>
  );
};
// </editor-fold>

export default CompanyIntro;
