import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { formatDistance } from 'date-fns';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Carousel, { CarouselRef } from 'react-bootstrap/Carousel';
import { Link, useLocation } from 'react-router-dom';
import {
  CONTINUOUS_SEARCH_DATE,
  getApiBaseUrl,
  getLimitedTextString,
} from 'utils/helper';
import { JobsState } from 'views/ReduInfoDisplay/ReduInfoDisplay';
import CarouselCard from '../CarouselCard';
import { debounce } from 'lodash';

// <editor-fold desc="InfoDisplay type definitions ...">
export type InfoDisplayProps = {
  academyLogo: string;
  title: string;
  infoMessage: string;
  tiitusLogo: string;
  sectorNameSlug: string;
  initializingText: string;
  initializing: boolean;
  jobs: JobsState;
  jobsLoaderText: string;
  visibleItems: number;
  timer: number;
};
// </editor-fold>

// <editor-fold desc="InfoDisplay component definition ...">

const splitArray = <T extends unknown>(array: T[], count: number) => {
  const arrayOfArrays: T[][] = [];
  while (array.length > 0) {
    const arrayElement = array.splice(0, count);
    arrayOfArrays.push(arrayElement);
  }
  return arrayOfArrays;
};

const InfoDisplay = ({
  academyLogo,
  title,
  infoMessage,
  tiitusLogo,
  sectorNameSlug,
  initializingText,
  initializing,
  jobs,
  jobsLoaderText,
  visibleItems,
  timer,
}: InfoDisplayProps): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const testRef = useRef<CarouselRef | null>(null);
  const [height, setHeight] = useState<number>(0);
  const domain = window.location.host;
  const [showItems] = useState(
    visibleItems >= 3 && visibleItems <= 6
      ? visibleItems
      : visibleItems < 3
      ? 3
      : 6,
  );
  const [interval] = useState(
    timer >= 3000 && timer <= 20000 ? timer : timer < 3000 ? 3000 : 20000,
  );
  const [width, setWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    if (testRef?.current) {
      setHeight(testRef?.current?.element?.lastElementChild?.clientHeight || 0);
    }
  });

  useEffect(() => {
    const handleResize = debounce(() => setWidth(window.innerWidth), 100);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Fragment>
      {initializing ? (
        <Container className="g-0">
          <Row className="g-0 justify-content-center align-items-center h-100">
            <Col xs="auto" className="me-4">
              <div
                className="spinner-border text-primary"
                style={{ width: '3rem', height: '3rem' }}
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </Col>
            <Col xs="auto">
              <p className="text-primary display-4 mb-0">{initializingText}</p>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid className="g-0 p-0 d-flex flex-column mh-100vh">
          <Row className="g-0 bg-white">
            <Col className="align-self-center">
              <Image
                src={academyLogo}
                alt="academy logo"
                className="d-block m-auto p-3"
                style={{ maxHeight: 150, maxWidth: 200 }}
              />
            </Col>
            <Col xs={9} className="align-self-center text-center pb-2">
              <h1>{getLimitedTextString(title, 65)}</h1>
              <Link to={`/${sectorNameSlug}`}>
                <span className="h2">{`${domain}/${sectorNameSlug}`}</span>
              </Link>
            </Col>
            <Col className="align-self-center">
              <Image
                src={tiitusLogo}
                alt="tiitus logo"
                className="d-block m-auto p-3"
                style={{ maxHeight: 150, maxWidth: 200 }}
              />
            </Col>
          </Row>
          <Row className="g-0 justify-content-center align-items-center flex-grow-1 ">
            {jobs?.loading ? (
              <Fragment>
                <Col xs="auto" className="me-4">
                  <div
                    className="spinner-border text-primary"
                    style={{ width: '3rem', height: '3rem' }}
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </Col>
                <Col xs="auto">
                  <p className="text-primary display-4 mb-0">
                    {jobsLoaderText}
                  </p>
                </Col>
              </Fragment>
            ) : (
              <Carousel
                interval={interval}
                variant="dark"
                controls={false}
                as={Container}
                fluid
                className="h-100 g-0 p-3"
                ref={testRef}
              >
                {jobs?.data?.length === 0 ? (
                  <Carousel.Item
                    key={`noEntriesContainer`}
                    as={Row}
                    className="h-100 g-0"
                  >
                    <Col xs={12} className="h-100 g-0">
                      <Container fluid className="h-100 d-flex flex-column">
                        <Row
                          className={`g-0 my-auto flex-grow-1 align-items-center justify-content-center`}
                        >
                          <Col>
                            <p className="text-center display-5 p-5">
                              {t(
                                'Valitettavasti yhtään avoinna olevaa työpaikkaa ei löytynyt',
                              )}
                            </p>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Carousel.Item>
                ) : null}
                {jobs?.data
                  ? splitArray([...jobs.data], showItems).map(
                      (carouselJobItem) => {
                        return (
                          <Carousel.Item
                            key={`carouselJobItemContainer${carouselJobItem[0].id}`}
                            as={Row}
                            className="h-100 g-0"
                          >
                            <Col xs={12} className="h-100 g-0">
                              <Container
                                fluid
                                className="h-100 d-flex flex-column"
                              >
                                <Row
                                  className={`g-0 my-auto flex-grow-1 align-items-stretch'${
                                    showItems === 3
                                      ? ' justify-content-center'
                                      : ''
                                  }`}
                                  style={{
                                    maxHeight: showItems === 3 ? 800 : '100%',
                                  }}
                                >
                                  {carouselJobItem.map((jobObject) => {
                                    return (
                                      <Col
                                        xs={12}
                                        sm={6}
                                        xl={
                                          12 /
                                          (showItems > 3 ? showItems / 2 : 3)
                                        }
                                        className="g-0 p-3"
                                        key={jobObject.id}
                                        style={{
                                          maxHeight:
                                            showItems === 3
                                              ? '100%'
                                              : height / 2,
                                        }}
                                      >
                                        <CarouselCard
                                          imageUrl={
                                            jobObject?.imageUrl || 'default'
                                          }
                                          infoMessage={infoMessage}
                                          title={
                                            getLimitedTextString(
                                              jobObject.title,
                                              70,
                                            ) || ''
                                          }
                                          logoUrl={jobObject.logoUrl}
                                          continuous={
                                            jobObject.applicationDeadline ===
                                            CONTINUOUS_SEARCH_DATE
                                          }
                                          daysLeft={parseInt(
                                            formatDistance(
                                              new Date(
                                                jobObject.applicationDeadline,
                                              ),
                                              new Date(),
                                              { addSuffix: false },
                                            ),
                                          )}
                                        />
                                      </Col>
                                    );
                                  })}
                                  {carouselJobItem.length < showItems
                                    ? Array.from(
                                        Array(
                                          showItems - carouselJobItem.length,
                                        ).keys(),
                                      ).map((emptyItem) => {
                                        return (
                                          <Col
                                            xs={12}
                                            sm={6}
                                            xl={
                                              12 /
                                              (showItems > 3
                                                ? showItems / 2
                                                : 3)
                                            }
                                            className="g-0 p-3"
                                            key={emptyItem}
                                          />
                                        );
                                      })
                                    : null}
                                </Row>
                              </Container>
                            </Col>
                          </Carousel.Item>
                        );
                      },
                    )
                  : null}
              </Carousel>
            )}
          </Row>
        </Container>
      )}
    </Fragment>
  );
};
// </editor-fold>

export default InfoDisplay;
