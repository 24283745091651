import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StudentIntroImage from 'assests/StudentIntroImage.png';
import StudentIntroImageSmall from 'assests/StudentIntroImageSmall.png';
import Image from 'react-bootstrap/Image';
import Button from 'components/Button';
import { getStudentRegisterUrl } from 'utils/helper';

// <editor-fold desc="StudentIntro type definitions ...">

export type StudentIntroProps = {
  title?: string;
  description?: string;
  sectorNameSlug: string;
};
// </editor-fold>

// <editor-fold desc="StudentIntro component definition ...">
const StudentIntro = ({
  title = '',
  description = '',
  sectorNameSlug,
}: StudentIntroProps): JSX.Element => {
  const { t } = useTranslation();

  const defaultIntroText = t(
    'Tiitus on oppilaitosten yhteinen keskitetty kanava opiskelijoiden työ- ja harjoittelupaikkojen ilmoittamiseen. Tiituksesta löytyvät siis kaikki opiskelijoiden työ- ja harkkapaikat.',
  );

  return (
    <Container>
      <Row>
        <Col xs={12} md={8} lg={9} className="p-4">
          <h2 className="text-primary">{title}</h2>
          <p>{description}</p>
          <Button href={getStudentRegisterUrl(sectorNameSlug)} target="_blank">
            {t('Luo opiskelijaprofiili')}
          </Button>
          <Container className="my-4 p-0">
            <Row>
              <Col>
                <h3>{t('Lataa sovellus')}</h3>
              </Col>
            </Row>
            <Row>
              <Col xs="auto" style={{ marginTop: 15 }}>
                <Button href="https://appstore.com/tiitus">{t('iOS')}</Button>
              </Col>
              <Col xs="auto" style={{ marginTop: 15 }}>
                <Button href="https://play.google.com/store/apps/details?id=fi.tiitusgroup.tiitus&hl=fi">
                  {t('Android')}
                </Button>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={StudentIntroImageSmall}
            />
            <source media="(min-width: 796px)" srcSet={StudentIntroImage} />
            <Image
              src={StudentIntroImage}
              alt="Tiitus student intro image"
              className="mw-100 d-block mx-auto"
            />
          </picture>
        </Col>
      </Row>
    </Container>
  );
};
// </editor-fold>

export default StudentIntro;
