import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GeneralIntroImage from 'assests/GeneralIntroImage.png';
import GeneralIntroImageSmall from 'assests/GeneralIntroImageSmall.png';
import Image from 'react-bootstrap/Image';
import Button from 'components/Button';

// <editor-fold desc="GeneralIntro type definitions ...">

export type GeneralIntroProps = {
  title?: string;
  description?: string;
};
// </editor-fold>

// <editor-fold desc="GeneralIntro component definition ...">
const GeneralIntro = ({
  title = '',
  description = '',
}: GeneralIntroProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row>
        <Col xs={12} md={8} lg={9} className="p-4">
          <h2 className="text-primary">{title}</h2>
          <p>{description}</p>
          <Button href="https://www.tiitus.fi/wiki/" target="_blank">
            {t('Ohjeet ja vinkit')}
          </Button>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={GeneralIntroImageSmall}
            />
            <source media="(min-width: 796px)" srcSet={GeneralIntroImage} />
            <Image
              src={GeneralIntroImage}
              alt="Tiitus general intro image"
              className="mw-100 d-block mx-auto"
            />
          </picture>
        </Col>
      </Row>
    </Container>
  );
};
// </editor-fold>

export default GeneralIntro;
