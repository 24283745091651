import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useParams, useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Paper from 'components/Paper';
import TiitusIntro from './TiitusIntro';
import GeneralIntro from './GeneralIntro';
import CompanyIntro from './CompanyIntro';
import StudentIntro from './StudentIntro';
import SupportIntro from './SupportIntro';
import ContactForm from './ContactForm';
import { fetchAcademies } from '../../store/academies';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import LanguageSelector from '../../components/LanguageSelector';

// <editor-fold desc="AcademyDetails type definitions ...">
// </editor-fold>

// <editor-fold desc="AcademyDetails component definition ...">
const AcademyDetails = (): JSX.Element => {
  const { t } = useTranslation();
  const params = useParams<{ nameSlug: string }>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const academyState = useAppSelector((state) => state.academies);
  const academy = useAppSelector((state) =>
    state.academies.academies.find((academy) => {
      return academy.region_sector.name_slug === params.nameSlug;
    }),
  );
  const regions_sector_general_settings = useAppSelector(
    (state) => state.regions_sector_general_settings,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (academy === undefined) {
      dispatch(fetchAcademies());
    }
    console.debug('academy', academy);
  }, [academy]);

  useEffect(() => {
    if (
      academy === undefined &&
      !academyState.loading &&
      academyState.academies.length > 0
    ) {
      history.push('/');
    }
  }, [academyState, academy]);

  return academy !== undefined ? (
    <Container className="py-2">
      <LanguageSelector />
      <Row className="my-4">
        <TiitusIntro
          logo={academy?.region_sector?.logo}
          sectorNameSlug={params.nameSlug}
          sectorName={
            academy?.region_sector?.default_region?.name ||
            academy?.region_sector?.name ||
            ''
          }
        />
      </Row>
      <Row>
        <Paper>
          <GeneralIntro
            title={academy?.region_sector?.msg_title_general}
            description={academy?.region_sector?.msg_text_general}
          />
        </Paper>
      </Row>
      <Row>
        <Paper>
          <CompanyIntro
            title={academy?.region_sector?.msg_title_company}
            description={academy?.region_sector?.msg_text_company}
            sectorId={academy?.region_sector?.id || -1}
          />
        </Paper>
      </Row>
      <Row>
        <Paper>
          <StudentIntro
            title={academy?.region_sector?.msg_title}
            description={academy?.region_sector?.msg_text}
            sectorNameSlug={params.nameSlug}
          />
        </Paper>
      </Row>
      <Row>
        <Paper>
          <SupportIntro
            description={
              regions_sector_general_settings?.instance?.tiitus_support_text
            }
          />
        </Paper>
      </Row>
      <Row>
        <Paper>
          <ContactForm
            contact_details={academy?.region_sector?.contact_details}
            name_slug={academy?.region_sector?.name_slug}
            title={academy?.region_sector?.name}
          />
        </Paper>
      </Row>
    </Container>
  ) : (
    <Container className="flex-fill">
      <Row className="justify-content-center align-items-center align-content-center h-100">
        <Col xs="auto" className="mr-2">
          <Spinner animation="border" variant="primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Col>
        <Col xs="auto">
          <h5 className="text-primary">
            {t('Ladataan oppilaitoksen tietoja')}...
          </h5>
        </Col>
      </Row>
    </Container>
  );
};
// </editor-fold>

export default AcademyDetails;
