import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SupportImage from 'assests/SupportImage.png';
import SupportImageSmall from 'assests/SupportImageSmall.png';
import Image from 'react-bootstrap/Image';
import Button from 'components/Button';

// <editor-fold desc="SupportIntro type definitions ...">

export type SupportIntroProps = {
  description: string;
};
// </editor-fold>

// <editor-fold desc="SupportIntro component definition ...">
const SupportIntro = ({ description }: SupportIntroProps): JSX.Element => {
  const { t } = useTranslation();

  const defaultIntroText = t(
    'Täältä löydät apua ja tukea palveluidemme käyttöön. Ennen yhteydenottoa käythän läpi ohjeet ja vinkit sekä yleisimmät kysymykset.\n' +
      '\n' +
      'Tiituksen tuki on auki arkisin klo 9-15. Pyrimme vastaamaan kaikkiin yhteydenottopyyntöihin mahdollisimman nopeasti.\n' +
      '\n' +
      'Yhteydenotot: support@tiitus.fi'
  );

  return (
    <Container>
      <Row>
        <Col xs={12} md={8} lg={9} className="p-4">
          <h2 className="text-primary">{t('Tiitus Support')}</h2>
          <p>
            {
              <Trans
                i18nKey="SupportDefaultIntroText" // optional -> fallbacks to defaults if not provided
                defaults={defaultIntroText} // optional defaultValue
              />
            }
          </p>
          <Button href="https://www.tiitus.fi/yhteystiedot/tiitus-support/" target="_blank">
            {t('Siirry supporttiin')}
          </Button>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <picture>
            <source media="(max-width: 768px)" srcSet={SupportImageSmall} />
            <source media="(min-width: 796px)" srcSet={SupportImage} />
            <Image
              src={SupportImage}
              alt="Tiitus support intro image"
              className="mw-100 d-block mx-auto"
            />
          </picture>
        </Col>
      </Row>
    </Container>
  );
};
// </editor-fold>

export default SupportIntro;
