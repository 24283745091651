import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import Card from 'components/Card';
import { fetchAcademies } from 'store/academies';
import LanguageSelector from '../../components/LanguageSelector';

// <editor-fold desc="AcademiesList type definitions ...">
// </editor-fold>

// <editor-fold desc="AcademiesList component definition ...">
const AcademiesList = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedAcademyType, setSelectedAcademyType] = useState('-1');
  const academies = useAppSelector((state) => state.academies);
  const defaultText =
    'Omalla oppilaitoksellasi saattaakin jo olla Tiitus eli Tiituksen rakentama työelämäpalvelu käytössä. Pääset kirjautumaan oppilaitoksesi opiskelijana palveluun tältä sivulta löytyvistä logoista. Monet oppilaitokset tahtovat tukea opiskelijoidensa työelämäyhteyksiä ottamalla oman työelämäpalvelun käyttöön. Saat lisätietoa oman oppilaitoksesi Tiituksesta oppilaitoksesi ura- ja työelämäpalveluista.';

  useEffect(() => {
    if (parseInt(selectedAcademyType) >= 0) {
      dispatch(fetchAcademies({ academy_type: selectedAcademyType }));
    } else {
      dispatch(fetchAcademies());
    }
  }, [selectedAcademyType]);

  const getDescriptionLimited = (descriptionText: string): string => {
    if (descriptionText.length > 160) {
      return descriptionText.substring(0, 160) + '...';
    } else {
      return descriptionText;
    }
  };

  return (
    <Container className="py-2" fluid="xl">
      <LanguageSelector />
      <Row>
        <Col xs={12} className="text-center pb-2">
          <h1>{t('Oman oppilaitoksen Tiitus')}</h1>
        </Col>
        <Col xs={12} className="text-center" style={{ padding: '0 30px' }}>
          <p>
            <Trans
              i18nKey="TiitusAcademyIntroText" // optional -> fallbacks to defaults if not provided
              defaults={defaultText} // optional defaultValue
            />
          </p>
        </Col>
        <Col xs={12} sm={6} xl={4} style={{ padding: '0 30px' }}>
          <FormControl
            as="select"
            value={selectedAcademyType}
            onChange={(e) => setSelectedAcademyType(e.target.value)}
          >
            <option value={'-1'}>{t('Kaikki')}</option>
            <option value={'0'}>{t('AMK')}</option>
            <option value={'1'}>{t('Ammatillinen koulutus')}</option>
            <option value={'2'}>{t('Lukio')}</option>
          </FormControl>
        </Col>
        <Col xs={12}>
          <Container fluid="xl">
            <Row>
              {academies.loading ? (
                <Col xs={12} className="pt-4">
                  <Container>
                    <Row className="justify-content-center align-items-center">
                      <Col xs="auto" className="mr-2">
                        <Spinner
                          animation="border"
                          variant="primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </Col>
                      <Col xs="auto">
                        <h5 className="text-primary">
                          {t('Ladataan oppilaitoksia')}...
                        </h5>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              ) : academies?.academies?.length > 0 ? (
                academies.academies.map((academy) => (
                  <Col
                    xs={12}
                    sm={6}
                    xl={4}
                    style={{ marginTop: 30 }}
                    key={academy.id}
                  >
                    <Card
                      imageUrl={academy?.region_sector?.logo || ''}
                      title={
                        academy?.region_sector?.name ||
                        academy?.region_sector?.default_region?.name ||
                        ''
                      }
                      description={
                        academy?.region_sector?.msg_text_general
                          ? getDescriptionLimited(
                              academy?.region_sector?.msg_text_general,
                            )
                          : ''
                      }
                      nameSlug={academy?.region_sector?.name_slug || ''}
                    />
                  </Col>
                ))
              ) : (
                <Col xs={12} style={{ marginTop: 30 }} className="text-center">
                  <h6>{t('Hakutuloksia ei löytynyt')}</h6>
                </Col>
              )}
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
// </editor-fold>

export default AcademiesList;
