import React, { Fragment, useContext, useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// <editor-fold desc="LanguageSelector type definitions ...">
// </editor-fold>

// <editor-fold desc="LanguageSelector component definition ...">

const getCountryCode = (language: string) => {
  switch (language) {
    case 'fi':
      return 'FI';
    case 'sv':
      return 'SE';
    case 'en':
      return 'GB';
    default:
      console.error('Unknown language');
  }
};

const LanguageSelector = (): JSX.Element => {
  const { i18n } = useTranslation();
  const [languages, setLanguages] = useState<string[]>([]);

  useEffect(() => {
    setLanguages(
      (i18n?.options?.supportedLngs || []).filter((lng) => lng !== 'cimode'),
    );
  }, [i18n]);

  const changeLanguage = (lng: string) => (): void => {
    i18n
      .changeLanguage(lng)
      .then((res) => console.debug('res', res))
      .catch((err) => console.debug('err', err));
  };

  console.debug('i18n', i18n);

  return (
    <Row>
      <Col>
        {languages.map((language) => {
          return (
            <div className="icon-button" onClick={changeLanguage(language)}>
              <ReactCountryFlag
                countryCode={getCountryCode(language)}
                svg
                style={{
                  position: 'absolute',
                  top: '.5em',
                  left: '.5em',
                  width: '2em',
                  height: '2em',
                }}
              />
            </div>
          );
        })}
      </Col>
    </Row>
  );
};
// </editor-fold>

export default LanguageSelector;
