import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TiitusIntroImage from 'assests/TiitusIntroImage.png';
import TiitusIntroImageSmall from 'assests/TiitusIntroImageSmall.png';
import Image from 'react-bootstrap/Image';
import Button from 'components/Button';
import { getMarketUrl, getTiitusIntroUrl } from '../../utils/helper';

// <editor-fold desc="TiitusIntro type definitions ...">

export type TiitusIntroProps = {
  logo?: string;
  sectorNameSlug: string;
  sectorName: string;
};
// </editor-fold>

// <editor-fold desc="TiitusIntro component definition ...">
const TiitusIntro = ({
  logo = '',
  sectorNameSlug,
  sectorName,
}: TiitusIntroProps): JSX.Element => {
  const { t } = useTranslation();

  const defaultIntroText =
    'Tiitus on oppilaitosten yhteinen keskitetty kanava opiskelijoiden työ- ja harjoittelupaikkojen ilmoittamiseen. Tiituksesta löytyvät siis kaikki opiskelijoiden työ- ja harkkapaikat.';
  const defaultIntroHeader =
    'Tervetuloa {{regionName}} Tiitus-palvelun etusivulle. ';

  return (
    <Container>
      <Row className="align-items-center">
        <Col xs={12} md={6} className="mw-100">
          <Image
            className="mb-4"
            src={logo}
            alt="academy logo"
            style={{ maxHeight: 200, maxWidth: '100%' }}
          />
          <h1 className="mb-4">
            <Trans
              i18nKey="AcademyDefaultIntroHeader"
              defaults={defaultIntroHeader}
              values={{ regionName: sectorName }}
            />
          </h1>
          <p className="mb-4">
            <Trans
              i18nKey="AcademyDefaultIntroText" // optional -> fallbacks to defaults if not provided
              defaults={defaultIntroText} // optional defaultValue
            />
          </p>
          <Row>
            <Col xs="auto" style={{ marginTop: 15 }}>
              <Button href={getMarketUrl(sectorNameSlug)} target="_blank">
                {t('Siirry työpaikkatorille')}
              </Button>
            </Col>
            <Col xs="auto" style={{ marginTop: 15 }}>
              <Button href={getTiitusIntroUrl(sectorNameSlug)} target="_blank">
                {t('Kirjaudu tästä')}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <picture>
            <source media="(max-width: 768px)" srcSet={TiitusIntroImageSmall} />
            <source media="(min-width: 796px)" srcSet={TiitusIntroImage} />
            <Image
              src={TiitusIntroImage}
              alt="Tiitus intro image"
              className="mw-100 d-block mx-auto"
            />
          </picture>
        </Col>
      </Row>
    </Container>
  );
};
// </editor-fold>

export default TiitusIntro;
