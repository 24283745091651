import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getApiBaseUrl } from 'utils/helper';
import InfoDisplay from 'components/InfoDisplay/InfoDisplay';

// <editor-fold desc="ReduInfoDisplay type definitions ...">

// An enum with all the types of actions to use in reduReducer
enum ReduActionTypes {
  SET_LOADING = 'SET_LOADING',
  REQUEST_SUCCESS = 'REQUEST_SUCCESS',
  LOAD_DATA = 'LOAD_DATA',
}

// An interface for reduReducer actions
interface ReduActions {
  type: ReduActionTypes;
  payload?: {
    id: number;
    logo?: string;
    infoDisplayTitle?: string;
    infoDisplayMessage?: string;
    sectorNameSlug?: string;
    regionType?: number;
    regionSector?: number;
  };
}

// A type for reduState
type ReduState = {
  loading: boolean;
  shouldLoad: boolean;
  data?: {
    id: number;
    logo?: string;
    infoDisplayTitle?: string;
    infoDisplayMessage?: string;
    sectorNameSlug?: string;
    regionType?: number;
    regionSector?: number;
  };
  error: boolean;
  errorMessage?: string;
};

// An enum with all the types of actions to use in tiitusReducer
enum TiitusActionTypes {
  SET_LOADING = 'SET_LOADING',
  REQUEST_SUCCESS = 'REQUEST_SUCCESS',
  LOAD_DATA = 'LOAD_DATA',
}

// An interface for tiitusReducer actions
interface TiitusActions {
  type: TiitusActionTypes;
  payload?: {
    id: number;
    logo?: string;
  };
}

// A type for tiitusState
type TiitusState = {
  loading: boolean;
  shouldLoad: boolean;
  data?: { id: number; logo?: string };
  error: boolean;
  errorMessage?: string;
};

// An enum with all the types of actions to use in jobsReducer
enum JobsActionTypes {
  SET_LOADING = 'SET_LOADING',
  REQUEST_SUCCESS = 'REQUEST_SUCCESS',
  LOAD_DATA = 'LOAD_DATA',
}

// An interface for jobsState data
interface JobsDataObject {
  id: number;
  title: string;
  applicationDeadline: string;
  imageUrl: string;
  logoUrl?: string;
}

// An interface for jobsReducer actions
interface JobsActions {
  type: JobsActionTypes;
  payload?: JobsDataObject[];
}

// A type for jobsState
export type JobsState = {
  loading: boolean;
  shouldLoad: boolean;
  data?: JobsDataObject[];
  error: boolean;
  errorMessage?: string;
};

export type ReduInfoDisplayProps = Record<string, never>;
// </editor-fold>

// <editor-fold desc="ReduInfoDisplay component definition ...">

const initialReduState: ReduState = {
  loading: false,
  shouldLoad: true,
  data: undefined,
  error: false,
  errorMessage: undefined,
};

const initialTiitusState: TiitusState = {
  loading: false,
  shouldLoad: true,
  data: undefined,
  error: false,
  errorMessage: undefined,
};

const initialJobsState: JobsState = {
  loading: false,
  shouldLoad: false,
  data: undefined,
  error: false,
  errorMessage: undefined,
};

const reduReducer = (reduState: ReduState, action: ReduActions) => {
  switch (action.type) {
    case ReduActionTypes.SET_LOADING:
      return {
        ...reduState,
        loading: true,
        shouldLoad: false,
      };
    case ReduActionTypes.REQUEST_SUCCESS:
      return {
        ...reduState,
        loading: false,
        data: action.payload,
      };
    default:
      return reduState;
  }
};

const tiitusReducer = (tiitusState: TiitusState, action: TiitusActions) => {
  switch (action.type) {
    case TiitusActionTypes.SET_LOADING:
      return {
        ...tiitusState,
        loading: true,
        shouldLoad: false,
      };
    case TiitusActionTypes.REQUEST_SUCCESS:
      return {
        ...tiitusState,
        loading: false,
        data: action.payload,
      };
    default:
      return tiitusState;
  }
};

const jobsReducer = (jobsState: JobsState, action: JobsActions) => {
  switch (action.type) {
    case JobsActionTypes.LOAD_DATA:
      return {
        ...jobsState,
        loading: false,
        shouldLoad: true,
      };
    case JobsActionTypes.SET_LOADING:
      return {
        ...jobsState,
        loading: true,
        shouldLoad: false,
      };
    case JobsActionTypes.REQUEST_SUCCESS:
      return {
        ...jobsState,
        loading: false,
        data: action.payload,
      };
    default:
      return jobsState;
  }
};

const ReduInfoDisplay = (props: ReduInfoDisplayProps): JSX.Element => {
  const { t } = useTranslation();
  const { timer, showResults, cityId } =
    useParams<{ timer: string; showResults: string; cityId: string }>();
  const [reduState, reduDispatch] = useReducer(reduReducer, initialReduState);
  const [tiitusState, tiitusDispatch] = useReducer(
    tiitusReducer,
    initialTiitusState,
  );
  const [jobsState, jobsDispatch] = useReducer(jobsReducer, initialJobsState);

  useEffect(() => {
    if (reduState.shouldLoad) {
      reduDispatch({ type: ReduActionTypes.SET_LOADING });
      fetch(`${getApiBaseUrl()}academies_public/redu/`)
        .then((response) => {
          return response.json().then(
            (data: {
              id: number;
              region_sector: {
                logo: string;
                name_slug: string;
                id: number;
                default_region: { id: number };
              };
              info_display: { title: string; info_message: string } | null;
            }) => {
              const {
                id,
                region_sector: {
                  logo,
                  name_slug: sectorNameSlug,
                  id: regionSector,
                  default_region: { id: regionType },
                },
                info_display,
              } = data;
              const {
                title: infoDisplayTitle,
                info_message: infoDisplayMessage,
              } = info_display || {};
              reduDispatch({
                type: ReduActionTypes.REQUEST_SUCCESS,
                payload: {
                  id,
                  logo,
                  infoDisplayTitle,
                  infoDisplayMessage,
                  sectorNameSlug,
                  regionSector,
                  regionType,
                },
              });
            },
          );
        })
        .catch((err) => console.error('err', err));
    }
  }, [reduState]);

  useEffect(() => {
    if (tiitusState.shouldLoad) {
      tiitusDispatch({ type: TiitusActionTypes.SET_LOADING });
      fetch(`${getApiBaseUrl()}regions/regions_sectors/?identifiers=1`)
        .then((response) => {
          return response
            .json()
            .then((data: { id: number; logo: string }[]) => {
              const { id, logo } = data[0];
              tiitusDispatch({
                type: TiitusActionTypes.REQUEST_SUCCESS,
                payload: { id, logo },
              });
            });
        })
        .catch((err) => console.error('err', err));
    }
  }, [tiitusState]);

  useEffect(() => {
    if (jobsState.shouldLoad) {
      jobsDispatch({ type: JobsActionTypes.SET_LOADING });
      fetch(
        `${getApiBaseUrl()}market_jobs/?region_types=${
          reduState?.data?.regionType?.toString() || ''
        }&city_ids=${cityId || ''}&regions_sectors=${
          reduState?.data?.regionSector?.toString() || ''
        }&limit=50&offset=0`,
      )
        .then((response) => {
          return response.json().then(
            (data: {
              results: {
                id: number;
                title: string;
                application_deadline: string | null;
                header_image: string | null;
                header_media_industry: {
                  id: number;
                  cover_image: string;
                };
                company: {
                  logo_media: {
                    image: {
                      full_size: string;
                    };
                  };
                };
              }[];
            }) => {
              const { results } = data;
              const payload = results.reduce(
                (acc: JobsDataObject[], current) => {
                  return [
                    ...acc,
                    {
                      id: current.id,
                      title: current.title,
                      applicationDeadline: current?.application_deadline || '',
                      imageUrl:
                        current.header_image ||
                        current.header_media_industry.cover_image,
                      logoUrl:
                        current?.company?.logo_media?.image?.full_size || '',
                    },
                  ];
                },
                [],
              );
              jobsDispatch({
                type: JobsActionTypes.REQUEST_SUCCESS,
                payload: payload,
              });
            },
          );
        })
        .catch((err) => console.error('err', err));
    }
  }, [jobsState]);

  useEffect(() => {
    if (
      !reduState.loading &&
      !reduState.shouldLoad &&
      !tiitusState.loading &&
      !tiitusState.shouldLoad &&
      cityId
    ) {
      jobsDispatch({ type: JobsActionTypes.LOAD_DATA });
    }
  }, [reduState, tiitusState, cityId]);

  useEffect(() => {
    const timeOutTimer = setTimeout(() => window.location.reload(), 3600000);
    return () => {
      clearTimeout(timeOutTimer);
    };
  }, []);

  const defaultIntroText = t(
    'Kaikki opiskelijoiden työpaikat yhdessä paikassa. Hae jo tänään!',
  );

  return (
    <InfoDisplay
      academyLogo={reduState?.data?.logo || ''}
      title={reduState?.data?.infoDisplayTitle || defaultIntroText}
      infoMessage={reduState?.data?.infoDisplayMessage || ''}
      tiitusLogo={tiitusState?.data?.logo || ''}
      sectorNameSlug={reduState?.data?.sectorNameSlug || ''}
      initializingText={`${t('Ladataan Redu infonäyttöä')}...`}
      initializing={
        reduState.loading ||
        reduState.shouldLoad ||
        tiitusState.loading ||
        tiitusState.shouldLoad
      }
      jobs={jobsState}
      jobsLoaderText={`${t('Ladataan työpaikkoja')}...`}
      visibleItems={parseInt(showResults)}
      timer={parseInt(timer)}
    />
  );
};
// </editor-fold>

export default ReduInfoDisplay;
