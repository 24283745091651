import * as actionTypes from './actionTypes';
import { Academy, AcademyAction, DispatchType, AcademiesState } from './types';
import { getApiBaseUrl } from '../../utils/helper';

const initialState: AcademiesState = {
  loading: false,
  academies: [],
  error: '',
};

const fetchAcademiesRequest = () => {
  return {
    type: actionTypes.GET_ACADEMIES_REQUEST,
  };
};

const fetchAcademiesSuccess = (academies: Academy[]) => {
  return {
    type: actionTypes.GET_ACADEMIES_SUCCESS,
    data: academies,
  };
};

const fetchAcademiesFailure = (error: string) => {
  return {
    type: actionTypes.GET_ACADEMIES_FAILURE,
    error: error,
  };
};

const reducer = (
  state = initialState,
  action: AcademyAction,
): AcademiesState => {
  switch (action.type) {
    case actionTypes.GET_ACADEMIES_REQUEST: {
      return { ...state, loading: true };
    }
    case actionTypes.GET_ACADEMIES_SUCCESS: {
      return {
        loading: false,
        academies: action?.data as Academy[],
        error: '',
      };
    }
    case actionTypes.GET_ACADEMIES_FAILURE: {
      return { loading: false, academies: [], error: action?.error as string };
    }
    default:
      return state;
  }
};

export const fetchAcademies = (params?: {
  academy_type: string;
}): ((dispatch: DispatchType) => void) => {
  const urlParams = new URLSearchParams({ ...params }).toString();
  return function (dispatch: DispatchType) {
    dispatch(fetchAcademiesRequest());
    fetch(`${getApiBaseUrl()}academies_public/?${urlParams}`)
      .then((response) => {
        response
          .json()
          .then((data) => dispatch(fetchAcademiesSuccess(data)))
          .catch((error) => dispatch(fetchAcademiesFailure(error)));
      })
      .catch((error) => dispatch(fetchAcademiesFailure(error)));
  };
};

export default reducer;
