/* tslint:disable */
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'components/Button';
import { getApiBaseUrl } from '../../utils/helper';

const defaultValues = {
  name: '',
  email: '',
  message: '',
};

const ContactForm = (props) => {
  const { title, contact_details, contactFormSuccess } = props;

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isValid,
    isTouched,
    isSubmitting,
    validateForm,
  } = props;

  const { t } = useTranslation();

  const getFormikInputProps = useCallback(
    (name) => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : '',
      onBlur: handleBlur,
      onChange: handleChange,
    }),
    [values, errors, touched, handleBlur, handleChange],
  );

  return (
    <Container>
      <Row>
        <Col xs={12} className="p-4 text-center">
          <h2>{t('Ole yhteydessä ja kysy lisää')}</h2>
        </Col>
      </Row>
      <Row>
        <Col className="p-4 text-center">
          <p>
            {t(
              'Lähetä viestiä toimialueen ylläpitäjälle tämän lomakkeen kautta tai ole suoraan yhteydessä yhteyshenkilöön',
            )}
            :
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="p-4 text-center">
          <b>{title}</b>
          <p>{contact_details}</p>
        </Col>
      </Row>
      {!contactFormSuccess ? (
        <>
          <Row>
            <Col xs={12} sm={6}>
              <div className="form-group">
                <input
                  {...getFormikInputProps('name')}
                  label={t('Nimi')}
                  className="form-control"
                  placeholder={t('Nimi')}
                />
                <div className="text-danger">
                  {touched['name'] ? errors['name'] : ''}
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className="form-group">
                <input
                  {...getFormikInputProps('email')}
                  label={t('Sähköposti')}
                  className="form-control"
                  placeholder={t('Sähköposti')}
                />
                <div className="text-danger">
                  {touched['email'] ? errors['email'] : ''}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="py-3">
            <Col>
              <div className="form-group">
                <textarea
                  {...getFormikInputProps('message')}
                  label={t('Viesti')}
                  className="form-control"
                  placeholder={t('Viesti')}
                ></textarea>
                <div className="text-danger">
                  {touched['message'] ? errors['message'] : ''}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-end">
            <Col xs="auto">
              <Button
                disabled={isSubmitting}
                onClick={() => validateForm().then(() => handleSubmit())}
              >
                {t('LÄHETÄ')}
              </Button>
              <div className="text-danger">
                {errors['general'] ? errors['general'] : ''}
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col className="p-4 text-center">
            <h3 className="text-success">{t('Viesti lähetetty, kiitos')}!</h3>
          </Col>
        </Row>
      )}
    </Container>
  );
};

const ContactFormFormik = withTranslation()(
  withFormik({
    validateOnMount: true,
    mapPropsToValues: (props) => {
      console.debug('props', props);
      const { preSetValues } = props;
      if (preSetValues) {
        return preSetValues;
      } else {
        return defaultValues;
      }
    },
    validationSchema: (props) => {
      const required = props.t('Kenttä on pakollinen');
      return Yup.object().shape({
        name: Yup.string().required(required).nullable(),
        message: Yup.string().required(required).nullable(),
        email: Yup.string()
          .email(props.t('Tarkista sähköpostiosoitteen muoto'))
          .required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      let data = new FormData();
      data.append('form', JSON.stringify(values));

      setSubmitting(true);
      fetch(
        `${getApiBaseUrl()}academies_public/${
          props.name_slug
        }/send_contact_form/`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(values),
        },
      )
        .then(function (res) {
          // return res.json();
          props.setContactFormSuccess(true);
        })
        .then(function (data) {
          setErrors({
            general: `Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen. (${JSON.stringify(
              data,
            )})`,
          });
          // alert( JSON.stringify( data ) )
        })
        .finally((r) => {
          setSubmitting(false);
        });
    },
    displayName: 'BasicForm',
  })(ContactForm),
);

const ContactFormView = (props) => {
  const [preSetValues, setPreSetValues] = useState(null);
  const [contactFormSuccess, setContactFormSuccess] = useState(false);

  return (
    <ContactFormFormik
      {...props}
      history={history}
      preSetValues={preSetValues}
      contactFormSuccess={contactFormSuccess}
      setContactFormSuccess={setContactFormSuccess}
    />
  );
};

export default ContactFormView;
