import { combineReducers } from '@reduxjs/toolkit';
import counterReducer from './test';
import academiesReducer from './academies';
import regionsSectorGeneralSettingsReducer from './regions_sector_general_settings';

const rootReducer = combineReducers({
  counter: counterReducer,
  academies: academiesReducer,
  regions_sector_general_settings: regionsSectorGeneralSettingsReducer,
});
export default rootReducer;
