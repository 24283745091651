import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { default as RBSCard } from 'react-bootstrap/Card';

// <editor-fold desc="CarouselCard type definitions ...">
interface CarouselCardProps {
  imageUrl: string;
  title: string;
  daysLeft: number;
  logoUrl?: string;
  continuous: boolean;
  infoMessage?: string;
}
// </editor-fold>

// <editor-fold desc="CarouselCard component definition ...">
const CarouselCard = ({
  imageUrl,
  title,
  daysLeft,
  logoUrl,
  continuous,
  infoMessage,
}: CarouselCardProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <RBSCard className="h-100 shadow-lg bg-light">
      <RBSCard.Header className="p-0">
        {continuous ? (
          <RBSCard.Text
            className={`text-center m-0 p-2 h5 text-white bg-success`}
          >
            {t('Jatkuva haku')}
          </RBSCard.Text>
        ) : (
          <RBSCard.Text
            className={`text-center m-0 p-2 h5 text-white ${
              daysLeft > 4 ? 'bg-success' : 'bg-danger'
            }`}
          >
            {daysLeft > 0
              ? t('Haku päättyy: {{days}} päivän päästä', { days: daysLeft })
              : t('Haku päättyy tänään')}
          </RBSCard.Text>
        )}
      </RBSCard.Header>
      <RBSCard.Body
        className="p-0 position-relative"
        style={{
          minHeight: 100,
          backgroundImage: `url(${imageUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <span className="helper"></span>
        <div
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <span className="text-center h2 text-white p-4">{title}</span>
        </div>
      </RBSCard.Body>
      {infoMessage ? (
        <RBSCard.Text
          className={`text-center m-0 p-2 h5 text-white bg-secondary`}
        >
          {infoMessage}
        </RBSCard.Text>
      ) : null}
      <RBSCard.Img
        className="p-4"
        style={{
          maxHeight: 100,
          maxWidth: '100%',
          objectFit: 'scale-down',
        }}
        variant="top"
        src={logoUrl}
      />
    </RBSCard>
  );
};
// </editor-fold>

export default CarouselCard;
