import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { default as RBSCard } from 'react-bootstrap/Card';
import Button from './Button';

// <editor-fold desc="Card type definitions ...">
interface CardProps {
  imageUrl: string;
  title: string;
  description?: string;
  nameSlug?: string;
  hideButtons?: boolean;
}
// </editor-fold>

// <editor-fold desc="Card component definition ...">
const Card = ({
  imageUrl,
  title,
  description,
  nameSlug,
  hideButtons = false,
}: CardProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <RBSCard className="h-100 shadow-sm">
      <div className="p-4 text-center" style={{ height: 120 }}>
        <RBSCard.Img
          style={{
            maxHeight: '100%',
            maxWidth: '100%',
            objectFit: 'scale-down',
          }}
          variant="top"
          src={imageUrl}
        />
      </div>
      <RBSCard.Body className="pt-0">
        <RBSCard.Title className="text-center">{title}</RBSCard.Title>
        {description ? (
          <RBSCard.Text className="text-center">{description}</RBSCard.Text>
        ) : null}
      </RBSCard.Body>
      {nameSlug && !hideButtons ? (
        <div className="text-center pb-3">
          <Button
            variant="primary"
            onClick={() => history.push(`/${nameSlug}`)}
          >
            {t('Avaa')}
          </Button>
        </div>
      ) : null}
    </RBSCard>
  );
};
// </editor-fold>

export default Card;
